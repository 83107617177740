/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Çelebi Sultan Mehmet’in 1419 yılında, Yeşil Türbe’nin binalarından biri olarak cami, imaret, türbe ve han ile birlikte inşa ettirdiği Yeşil Medrese, günümüzde Türk-İslam Eserleri Müzesi olarak kullanılmaktadır. 1930-1972 yılları arasında Bura Arkeoloji Müzesi’ne ev sahipliği yapan ve genel bir müze özelliğinde olan bina 1975 yılından itibaren Türk İslam Eserleri Müzesi olarak ziyarete açılmıştır.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
